import {
  Avatar,
  Button,
  Card,
  Divider,
  Form,
  Image,
  Input,
  InputNumber,
  List,
  message,
  Tabs,
  Tag,
  Modal,
  Tooltip,
  Badge,
  Skeleton,
  Carousel
} from 'antd'
import React, { useState, useEffect, useMemo } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import ProductImages from './ProductImages'
// import ProductInfoItem from "../ProductInfoItem";
import {
  KeyOutlined,
  FilePdfOutlined,
  AndroidOutlined,
  RetweetOutlined,
  CommentOutlined,
  CarOutlined,
  ShoppingCartOutlined,
  UpOutlined,
  RotateLeftOutlined,
  YoutubeOutlined
} from '@ant-design/icons'
import ProductDocuments from './ProductDocuments'
import ApplicationList from './ApplicationList'
import ProductOEMList from './ProductOEMList'
import ProductDescription from './ProductDescription'
import VehiclePartsArea from '@/components/assistant/VehiclePartsArea/VehiclePartsArea'
import { useDispatch, useSelector } from 'react-redux'

import { setSiteSettings } from '@/store/settings'
import { useTranslation } from 'react-i18next'
import ProductBuyComponent from '@/components/products/BuyArea/BuyComponent'
// import useIsAddedBasketProduct from "@/hooks/useIsAddedBasketProduct";

import ReactJson from 'react-json-view'
import getCatalogName from '@/helpers/getCatalogName'
import ApplicationListTecdoc from './ApplicationListTecdoc'
import ProductLinksComponent from './ProductLinksComponent'
import RotateImage from './RotateImage'

import CopyIcon from '../icons/CopyIcon'
import CrossIcon from '../icons/CrossIcon'
import NotificationIcon from '../icons/NotificationIcon'
import DimensionalIcon from '../icons/DimensionalIcon'
import YoutubeIcon from '../icons/YoutubeIcon'
import DocumentIcon from '../icons/DocumentIcon'
import CarIcon from '../icons/CarIcon'
import OemIcon from '../icons/OemIcon'
import BuyAreav2 from './BuyAreav2/BuyAreav2'
import OtherSellers from './BuyAreav2/OtherSellers'
import { getFastestAvability, getOtherSeller } from '@/core/avability/avability2'
import SubPartsIcon from '../icons/SubPartsIcon'

const Product = ({ product, Series, plate, onlyAvability = false, deliveries }) => {
  const isAddedBasket = false // useIsAddedBasketProduct({ uniqId: product.uniqId });

  const { t } = useTranslation()
  const [developer, setDeveloper] = useState(false)
  const [modalVisibles, setModalVisibles] = useState({ Documents: false, ApplicationList: false, Description: false, Oem: false, Parts: false })
  const settings = useSelector((e) => e.settings)
  const [devClicker, setDevClicker] = useState(0)
  const [loading, setLoading] = useState(false)
  const [avability, setAvability] = useState({})

  const catalogName = useMemo(() => getCatalogName(), [])

  const d = useDispatch()
  const onCopyHandler = () => {
    message.success('Copy to clipboard !')
    setDevClicker(devClicker + 1)
  }
  useEffect(() => {
    if (devClicker > 10) {
      d(
        setSiteSettings({
          type: 'developerMode',
          value: true
        })
      )
    }
  }, [devClicker])

  const [showOtherSellers, setShowOtherSellers] = useState(false)

  let remarks1,
    remarks2 = []
  if (Array.isArray(product.Remarks)) {
    const halfIndex = Math.ceil(product.Remarks.length / 2)
    remarks1 = product.Remarks.slice(0, halfIndex)
    remarks2 = product.Remarks.slice(halfIndex, product.Remarks.length)
  }

  const fastestAvability = getFastestAvability(product?.avability, deliveries)

  const otherSellers = getOtherSeller(product?.avability)

  return (
    <div className="w-full" style={{ display: onlyAvability == true && loading == false && avability.SaleStatus == false ? 'none' : '' }}>
      {product ? (
        <div className="w-full">
          <Modal
            width={'100%'}
            footer={null}
            title="Documents"
            open={modalVisibles.Documents}
            onCancel={() => setModalVisibles({ ...modalVisibles, Documents: false })}
          >
            <ProductDocuments Documents={product.Documents} />
          </Modal>

          <Modal
            width={'100%'}
            title="Application List"
            footer={null}
            open={modalVisibles.ApplicationList}
            onCancel={() => setModalVisibles({ ...modalVisibles, ApplicationList: false })}
          >
            {catalogName == 'TECDOC' ? (
              <ApplicationListTecdoc
                onSelectVehicle={() => {
                  setModalVisibles({ ...modalVisibles, ApplicationList: false })
                }}
                reference={product.ProductReference}
                brandId={product.CatalogBrandId}
              />
            ) : (
              <ApplicationList
                onAddVehicle={() => setModalVisibles({ ...modalVisibles, ApplicationList: false })}
                Series={Series}
                reference={product.ProductReference}
                brandId={product.CatalogBrandId}
              />
            )}
          </Modal>

          {/* <Modal footer={null} open={modalVisibles.Description} onCancel={() => setModalVisibles({ ...modalVisibles, Description: false })}>
                    <ProductDescriptionList  LinkRemarks={product.LinkRemarks ? product.LinkRemarks : []} Description={product.Description} />
                </Modal> */}

          <Modal footer={null} open={modalVisibles.Oem} onCancel={() => setModalVisibles({ ...modalVisibles, Oem: false })}>
            <ProductOEMList
              list={
                product && product.AlternativeProductReference && product.AlternativeProductReference.OEM
                  ? product.AlternativeProductReference.OEM
                  : []
              }
            />
          </Modal>

          <Modal footer={null} open={modalVisibles.RotateImages} onCancel={() => setModalVisibles({ ...modalVisibles, RotateImages: false })}>
            <RotateImage links={product.RotateImages} />
          </Modal>

          <Modal footer={null} open={modalVisibles.Links} onCancel={() => setModalVisibles({ ...modalVisibles, Links: false })}>
            <ProductLinksComponent links={product.Links} />
          </Modal>

          <Modal width={'100%'} footer={null} open={modalVisibles.Parts} onCancel={() => setModalVisibles({ ...modalVisibles, Parts: false })}>
            <VehiclePartsArea productGroupId={product?.CatalogProductGroupId} plate={plate} productReference={product.ProductReference} />
          </Modal>

          {catalogName == 'ALDOC' && (
            <Modal
              width={'100%'}
              footer={null}
              open={modalVisibles.subParts}
              onCancel={() => setModalVisibles({ ...modalVisibles, subParts: false })}
            >
              <VehiclePartsArea
                productGroupId={product?.CatalogProductGroupId}
                plate={plate}
                series={Series}
                catalogBrandId={product?.CatalogBrandId}
                productReference={product.ProductReference}
              />
            </Modal>
          )}

          <Modal width={'100%'} open={developer} onCancel={() => setDeveloper(false)}>
            <ReactJson src={product} />
          </Modal>

          <div className="mx-auto p-2.5 bg-white rounded-[6px] border border-neutral-200 gap-2.5 mb-[10px]">
            <div className=" w-full flex flex-wrap  justify-between mb-1  ">
              <div className="w-full flex flex-1   flex-wrap">
                <div className="w-full sm:w-1/2 md:w-1/5 p-[10px] flex relative justify-center">
                  {product.Image.length > 0 ? (
                    <Image.PreviewGroup items={product.Image}>
                      <Carousel draggable className="w-[170px]" infinite={false}>
                        {product.Image.map((item, index) => {
                          return (
                            <Image
                              key={index}
                              className="w-full h-[170px] relative object-cover relative object-scale-down  self-stretch grow shrink basis-0"
                              src={item}
                              style={{ width: 'auto' }}
                            />
                          )
                        })}
                      </Carousel>
                    </Image.PreviewGroup>
                  ) : (
                    <div className="flex justify-center" style={{ width: '50%' }}>
                      <img
                        className="w-full  h-[170px] relative object-cover relative object-scale-down  self-stretch grow shrink basis-0"
                        alt="Image"
                        src="https://material.parts-soft.net/image.php?brandId=0&productreference=0"
                      />
                    </div>
                  )}
                </div>
                <div className="w-full sm:w-1/2 md:w-1/4 p-[10px] flex flex-col justify-between">
                  <div className="flex flex-col items-start gap-[5px] relative self-stretch w-full flex-[0_0_auto]">
                    <div className="inline-flex items-center gap-[5px] p-[3px] relative flex-[0_0_auto] bg-[#f6f7f9] rounded-[6px] overflow-hidden">
                      <div className="inline-flex flex-col items-start p-[3px] relative flex-[0_0_auto] bg-white rounded-[3px] overflow-hidden">
                        <img
                          className="w-full h-[15px] relative object-cover"
                          alt="Image"
                          src={`https://aksamlar.com/public/brands-aksamlar/${product?.BrandId}.png`}
                          onError={(e) => {
                            e.target.src = 'https://material.parts-soft.net/image.php?brandId=-1&productreference=-1'
                          }}
                        />
                      </div>
                      <div className="relative   [font-family:'Poppins-Medium',Helvetica] font-medium text-[#565d6ccc] text-[14px]">
                        {product?.BrandName}
                      </div>
                    </div>
                    <CopyToClipboard text={product?.ProductReference} onCopy={() => onCopyHandler()}>
                      <div className="flex items-center gap-[5px] relative self-stretch w-full flex-[0_0_auto] rounded-[4px] overflow-hidden">
                        <div
                          className="relative w-fit [font-family:'Poppins-Bold',Helvetica] font-bold text-[#eb7130] text-[24px] tracking-[0] leading-[normal] whitespace-nowrap cursor-pointer"
                          style={{ color: settings?.['HeaderBgColor'] }}
                        >
                          {product?.ProductReference}
                        </div>
                        <div className="inline-flex flex-col items-start p-[4px] relative flex-[0_0_auto] bg-[#f4f4f4] rounded-[6px] overflow-hidden cursor-pointer">
                          <CopyIcon className="w-[12px] h-[14px] top-[2px] left-0 -rotate-180" />
                        </div>
                      </div>
                    </CopyToClipboard>
                    <div className="flex items-start px-0 py-[5px] relative self-stretch w-full flex-[0_0_auto] rounded-[4px] relative w-fit [font-family:'Poppins-Regular',Helvetica] font-normal text-[#565d6c99] text-[16px] tracking-[0] leading-[normal] whitespace-nowrap overflow-hidden">
                      {product?.CatalogProductGroupName}
                    </div>
                    {/* <div className="flex items-start px-0 py-[5px] relative self-stretch w-full flex-[0_0_auto] rounded-[4px] relative w-fit [font-family:'Poppins-Regular',Helvetica] font-normal text-[#565d6c99] text-[16px] tracking-[0] leading-[normal] whitespace-nowrap overflow-hidden">

                  </div> */}

                    {product?.CommericalName && <Tag> {product?.CommericalName}</Tag>}

                    {product?.StatusCodeName && <Tag className="mb-1"> {product?.StatusCodeName}</Tag>}
                  </div>
                  <div className="flex gap-[5px] relative self-stretch flex-[0_0_auto] justify-start items-start gap-[5px] inline-flex">
                    <a
                      className="flex items-center justify-center p-[5px] relative self-stretch flex-[0_0_auto] rounded-[6px] border-[1px] border-solid border-[#f0f0f0]"
                      style={{ backgroundColor: `${settings['HeaderBgColor']}27` }}
                      onClick={() => setModalVisibles({ ...modalVisibles, Parts: true })}
                    >
                      <CrossIcon color={settings['HeaderBgColor']} />
                    </a>

                    <a className="flex items-center justify-center p-[5px] relative self-stretch flex-[0_0_auto] rounded-[6px] border-[1px] border-solid border-[#f0f0f0]">
                      <NotificationIcon />
                    </a>

                    {product?.RotateImages?.length ? (
                      <a
                        className="flex items-center justify-center p-[5px] relative self-stretch flex-[0_0_auto] rounded-[6px] border-[1px] border-solid border-[#f0f0f0]"
                        style={{ backgroundColor: `${settings['HeaderBgColor']}27` }}
                        onClick={() => setModalVisibles({ ...modalVisibles, RotateImages: true })}
                      >
                        <DimensionalIcon color={settings['HeaderBgColor']} />
                      </a>
                    ) : (
                      <a className="flex items-center justify-center p-[5px] relative self-stretch flex-[0_0_auto] rounded-[6px] border-[1px] border-solid border-[#f0f0f0]">
                        <DimensionalIcon />
                      </a>
                    )}

                    {product?.Links?.length ? (
                      <a
                        className="flex items-center justify-center p-[5px] relative self-stretch flex-[0_0_auto] rounded-[6px] border-[1px] border-solid border-[#f0f0f0]"
                        style={{ backgroundColor: `${settings['HeaderBgColor']}27` }}
                        onClick={() => setModalVisibles({ ...modalVisibles, Links: true })}
                      >
                        <YoutubeIcon color={settings['HeaderBgColor']} />
                      </a>
                    ) : (
                      <a className="flex items-center justify-center p-[5px] relative self-stretch flex-[0_0_auto] rounded-[6px] border-[1px] border-solid border-[#f0f0f0]">
                        <YoutubeIcon />
                      </a>
                    )}

                    {product.Documents && product.Documents.length ? (
                      <a
                        className="flex items-center justify-center p-[5px] relative self-stretch flex-[0_0_auto] rounded-[6px] border-[1px] border-solid border-[#f0f0f0]"
                        style={{ backgroundColor: `${settings['HeaderBgColor']}27` }}
                        onClick={() => setModalVisibles({ ...modalVisibles, Documents: true })}
                      >
                        <DocumentIcon color={settings['HeaderBgColor']} />
                      </a>
                    ) : (
                      <a className="flex items-center justify-center p-[5px] relative self-stretch flex-[0_0_auto] rounded-[6px] border-[1px] border-solid border-[#f0f0f0]">
                        <DocumentIcon />
                      </a>
                    )}

                    {catalogName == 'ALDOC' && (
                      <a
                        className="flex items-center justify-center p-[5px] relative self-stretch flex-[0_0_auto] rounded-[6px] border-[1px] border-solid border-[#f0f0f0]"
                        style={{ backgroundColor: `${settings['HeaderBgColor']}27` }}
                        onClick={() => setModalVisibles({ ...modalVisibles, subParts: true })}
                      >
                        <SubPartsIcon color={settings['HeaderBgColor']} />
                      </a>
                    )}

                    {product.CatalogBrandId ? (
                      <a
                        className="flex items-center justify-center p-[5px] relative self-stretch flex-[0_0_auto] rounded-[6px] border-[1px] border-solid border-[#f0f0f0]"
                        style={{ backgroundColor: `${settings['HeaderBgColor']}27` }}
                        onClick={() => setModalVisibles({ ...modalVisibles, ApplicationList: true })}
                      >
                        <CarIcon color={settings['HeaderBgColor']} />
                      </a>
                    ) : (
                      <a className="flex items-center justify-center p-[5px] relative self-stretch flex-[0_0_auto] rounded-[6px] border-[1px] border-solid border-[#f0f0f0]">
                        <CarIcon />
                      </a>
                    )}

                    <a
                      className="flex items-center justify-center p-[5px] relative self-stretch flex-[0_0_auto] rounded-[6px] border-[1px] border-solid border-[#f0f0f0]"
                      style={{ backgroundColor: `${settings['HeaderBgColor']}27` }}
                      onClick={() => setModalVisibles({ ...modalVisibles, Oem: true })}
                    >
                      <OemIcon color={settings['HeaderBgColor']} />
                    </a>

                    {settings['developerMode'] == true && (
                      <a
                        className="flex items-center justify-center p-[5px] relative self-stretch flex-[0_0_auto] rounded-[6px] border-[1px] border-solid border-[#f0f0f0]"
                        style={{ backgroundColor: `${settings['HeaderBgColor']}27` }}
                        onClick={() => setDeveloper(true)}
                      >
                        <AndroidOutlined />
                      </a>
                    )}
                  </div>
                </div>

                {Array.isArray(remarks1) && remarks1.length > 0 && (
                  <div className="w-full sm:w-1/2 md:w-1/4 p-[10px]">
                    {remarks1.map((item, index) => {
                      let remarks = item?.Remark.split(':')
                      return (
                        <div
                          className={`flex w-full items-start justify-between px-[5px] py-[5px] relative flex-[0_0_auto] ${
                            index % 2 === 0 ? 'bg-[#f6f7f9]' : 'bg-white'
                          } rounded-[4px] overflow-hidden`}
                        >
                          <div className="relative w-fit [font-family:'Poppins-Regular',Helvetica] font-normal text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                            {remarks?.[0]?.trim() || ''} :
                          </div>
                          <div className="relative w-fit [font-family:'Poppins-Regular',Helvetica] font-normal text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                            {remarks?.[1]?.trim() || ''}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )}

                {Array.isArray(remarks2) && remarks2.length > 0 && (
                  <div className="w-full sm:w-1/2 md:w-1/4 p-[10px]">
                    {remarks2.map((item, index) => {
                      let remarks = item?.Remark.split(':')
                      return (
                        <div
                          className={`flex w-full items-start justify-between px-[5px] py-[5px] relative flex-[0_0_auto] ${
                            index % 2 === 0 ? 'bg-[#f6f7f9]' : 'bg-white'
                          } rounded-[4px] overflow-hidden`}
                        >
                          <div className="relative w-fit [font-family:'Poppins-Regular',Helvetica] font-normal text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                            {remarks?.[0]?.trim() || ''} :
                          </div>
                          <div className="relative w-fit [font-family:'Poppins-Regular',Helvetica] font-normal text-[#565d6c] text-[12px] tracking-[0] leading-[normal]">
                            {remarks?.[1]?.trim() || ''}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
              <div className="w-full max-h-[170px] sm:w-1/2 md:w-1/6 p-2 bg-neutral-100 rounded-md flex flex-col justify-between inline-flex flex-wrap">
                <Skeleton active={true} loading={product?.loadingAvability}>
                  <BuyAreav2
                    externalInformation={{
                      Image: product.Image?.length ? product.Image[0] : null,
                      Category: product?.CatalogProductGroupName ? product?.CatalogProductGroupName : null
                    }}
                    Plate={plate}
                    deliveries={deliveries}
                    otherSellers={otherSellers}
                    onClickOtherSellers={() => setShowOtherSellers(!showOtherSellers)}
                    fastestAvability={fastestAvability}
                  />
                </Skeleton>
              </div>
            </div>
            <div className={`flex flex-col gap-[5px] w-full relative overflow-y-auto ${showOtherSellers ? '' : 'hidden'}`}>
              <OtherSellers otherSellers={otherSellers} />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default React.memo(Product)
